import { memo } from 'react'
import isEqual from 'react-fast-compare'

import { FireDesktopOnlyCarousel } from './FireDesktopOnlyCarousel'
import {
  FireResponsiveCarousel,
  FireResponsiveCarouselProps,
} from './FireResponsiveCarousel'

export type FireCarouselProps = FireResponsiveCarouselProps & {
  isDesktopOnly?: boolean
}

export const FireCarousel = memo(
  ({ isDesktopOnly = false, ...rest }: FireCarouselProps) => {
    const Component = isDesktopOnly
      ? FireDesktopOnlyCarousel
      : FireResponsiveCarousel
    return <Component {...rest} />
  },
  isEqual
)
