import { ReactElement } from 'react'

import {
  StyledDesktopView,
  StyledMobileView,
} from './FireDesktopOnlyCarousel.styles'
import {
  FireResponsiveCarousel,
  FireResponsiveCarouselProps,
} from './FireResponsiveCarousel'

export type FireDesktopOnlyCarouselProps = {
  children: ReactElement | ReactElement[]
  className?: string
} & Pick<
  FireResponsiveCarouselProps,
  | 'onHandleScrollCallback'
  | 'moveToRef'
  | 'showPaginationDots'
  | 'fullWidthItems'
>

export function FireDesktopOnlyCarousel({
  children,
  className,
  ...rest
}: FireDesktopOnlyCarouselProps) {
  return (
    <div className={className}>
      <StyledDesktopView>
        <FireResponsiveCarousel {...rest}>{children}</FireResponsiveCarousel>
      </StyledDesktopView>
      <StyledMobileView>{children}</StyledMobileView>
    </div>
  )
}
